<template>
  <Inventory :pageType="pageType"></Inventory>
</template>

<script>
import Inventory from "@/views/modules/collection/collect/inventory.vue";

export default {
      name: "entryReview",
    components: {Inventory},
    data(){
        return{
            pageType:'entryReview',
        }
    }
}
</script>

<style scoped>

</style>